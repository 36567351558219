// import './App.css';
import '../../App.css'
import { useEffect, useState } from 'react';
import { Modal, Input, DatePicker } from 'antd';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import dayjs from 'dayjs';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import { format } from 'date-fns';


function RollingCommission() {
    const dateFormat = 'DD/MM/YYYY';

    const location = useLocation();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(true)
    const [passwordVisible, setPasswordVisible] = useState(false);
    const showAgeModal = () => {
        setIsOpen(true);
    };
    const onChange = (date, dateString) => {
        console.log(date, dateString);
    };

    // ***************  Calendar Start ****************

    const [fromDate, setFromDate] = useState(null);
    const [showFromCalendar, setShowFromCalendar] = useState(false);

    const handleFromDate = (newDate) => {
        console.log('newDate', newDate);
        if (newDate) {
            setFromDate(newDate);
            setShowFromCalendar(false);
        }
        // Hide the calendar after selecting a date
    };

    const toggleFromCalendar = () => {
        setShowFromCalendar(!showFromCalendar);
    };

    const [toDate, setToDate] = useState(null);
    const [showToCalendar, setShowToCalendar] = useState(false);

    const handleToDate = (newDate) => {
        if (newDate) {
            setToDate(newDate);
            setShowToCalendar(false); // Hide the calendar after selecting a date
        }

    };

    const toggleToCalendar = () => {
        setShowToCalendar(!showToCalendar);
    };
    const formattedFromDate = fromDate ? format(fromDate, 'dd-MM-yyyy') : format(new Date, 'dd-MM-yyyy');
    const formattedToDate = toDate ? format(toDate, 'dd-MM-yyyy') : format(new Date, 'dd-MM-yyyy');
    console.log("Formatted Date:", formattedFromDate); // Debug: log the formatted date

    // ***************  Calendar End ****************


    return (
        <>
            {/* Desktop */}
            <div className='hidden lg:block mt-[6px] !mb-4 text-[0.75rem] relative flex flex-col min-w-[0] [word-wrap:break-word]   rounded-[.25rem]'>
                <div className='grid grid-cols-12 mt-[22.5px] mb-[40px]'>
                    <div className='col-span-12'>
                        <div className=' flex-[0_0_auto] w-full'>
                            <div className='border-r-[1px] border-r-[#c8ced3] border-l-[1px] border-l-[#c8ced3] mb-[24px] rounded-[.25rem] pt-[10px]  pb-[0] w-full bg-[#e0e6e6] border-b-[1px] border-b-[#000501] border-t-[2px] border-t-[#000501] text-[#212529] [word-wrap:break-word] border-[1px] border-[solid] border-[#0000002d]'>
                                <div className='!p-0 flex-auto'>
                                    <div className='flex flex-wrap'>
                                        <div className='!pr-[17.5px] pl-[calc(1.5rem*.5)] !mb-4 flex-[0_0_auto] w-[33.33333333%]'>
                                            <select id="datasourceSelect" className='pl-[.95rem] [text-indent:1px] appearance-none   block w-full h-[calc(1.5em+.75rem+2px)] px-[.75rem] py-[.375rem] text-[.875rem] font-normal leading-normal text-[#5c6873] border-[1px] border-[solid] border-[#ccc] rounded-[.25rem] [transition:border-color_.15s_ease-in-out,_box-shadow_.15s_ease-in-out] [word-wrap:normal]' style={{ background: '#fff url(https://aura444.com/Down-Arrow.696f11d0c8727f3b.svg) right 93% / 8% 12px no-repeat', backgroundPosition: 'right 0% bottom 45%' }}>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' disabled value="">Data Source</option>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' value="LIVE">LIVE DATA</option>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' value="BACKUP">BACKUP DATA</option>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' value="OLD">OLD DATA</option>
                                            </select>
                                        </div>

                                        <div className='!pr-[18px] pl-[calc(1.5rem*.5)] !mb-4 flex-[0_0_auto] w-[33.33333333%]'>
                                            <select id="sportSelect" name='betType' className='pl-[.95rem] [text-indent:1px] appearance-none   block w-full h-[calc(1.5em+.75rem+2px)] px-[.75rem] py-[.375rem] text-[.875rem] font-normal leading-normal text-[#5c6873] border-[1px] border-[solid] border-[#ccc] rounded-[.25rem] [transition:border-color_.15s_ease-in-out,_box-shadow_.15s_ease-in-out]' style={{ background: '#fff url(https://aura444.com/Down-Arrow.696f11d0c8727f3b.svg) right 93% / 8% 12px no-repeat', backgroundPosition: 'right 0% bottom 45%' }}>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' value="" disabled="">Select Sport</option>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' value="4"> Cricket </option>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' value="2"> Tennis </option>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' value="66102"> Casino </option>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' value="1"> Soccer </option>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' value="7"> Horse Racing </option>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' value="4339"> Greyhound Racing </option>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' value="27979456"> Kabaddi </option>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' value="7522"> Basketball </option>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' value="2378961"> Politics </option>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' value="66101"> Virtual Sports </option>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' value="66104"> Lottery </option>
                                            </select>
                                        </div>

                                        <div className='!pr-[18px] pl-[calc(1.5rem*.5)] !mb-4 flex-[0_0_auto] w-[33.33333333%]'>
                                            <div className='relative flex flex-wrap items-stretch w-[99%]'>
                                                <div className='block w-full h-[calc(1.5em+.75rem+2px)] text-[.875rem] font-normal leading-normal text-[#5c6873] rounded-[.25rem] [transition:border-color_.15s_ease-in-out,_box-shadow_.15s_ease-in-out] relative flex-auto !p-0 !bg-[#e5e7ea] !border-[none]'>
                                                    <div className='!border-[1px] !border-[solid] !border-[#cccccc] relative table [border-spacing:0] bg-[#fff] rounded-[.25rem]'>
                                                        {/* <input type="date" id="date-time" name="date-time" className='h-[34px] text-[.75rem] !bg-[#e5e7ea] w-full outline-none table-cell absolute overflow-hidden overflow-ellipsis whitespace-nowrap pl-[6px] text-[#555]' /> */}
                                                        <DatePicker className='flex items-center h-[34px] border-none text-[.75rem] !bg-[#e5e7ea] w-full outline-none rounded-[.25rem] absolute overflow-hidden overflow-ellipsis whitespace-nowrap pl-[6px] text-[#555]' defaultValue={dayjs(new Date().toLocaleDateString(), 'DD/MM/YYYY')} format={dateFormat} onChange={onChange} />
                                                        <div className='h-[34px] relative align-middle whitespace-nowrap w-[1%] table-cell text-[0] [text-indent:9px!important] font-normal rounded-[.25rem] text-[#5c6873]'>
                                                            <button type='button' className='text-[#000] flex items-center justify-center h-full p-0 outline-[0] rounded-tl-[0] rounded-br-[4px] rounded-tr-[4px] rounded-bl-[0] !bg-[#c8ced2] !w-[40px]'>
                                                                <svg fill='black' width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M18 5V8H15V5H9V8H6V5H4V20H20V5H18ZM7 19H5V17H7V19ZM7 16H5V14H7V16ZM7 13H5V11H7V13ZM10 19H8V17H10V19ZM10 16H8V14H10V16ZM10 13H8V11H10V13ZM13 19H11V17H13V19ZM13 16H11V14H13V16ZM13 13H11V11H13V13ZM16 19H14V17H16V19ZM16 16H14V14H16V16ZM16 13H14V11H16V13ZM19 19H17V17H19V19ZM19 16H17V14H19V16ZM19 13H17V11H19V13Z" />
                                                                    <path d="M7 4H8V7H7V4Z" />
                                                                    <path d="M16 4H17V7H16V4Z" />
                                                                </svg>
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='!pr-[18px] pl-[calc(1.5rem*.5)] !mb-[10px] flex-[0_0_auto] w-[33.33333333%]'>
                                            <div className='relative flex flex-wrap items-stretch w-[99%]'>
                                                <div className='block w-full h-[calc(1.5em+.75rem+2px)] text-[.875rem] font-normal leading-normal text-[#5c6873] rounded-[.25rem] [transition:border-color_.15s_ease-in-out,_box-shadow_.15s_ease-in-out] relative flex-auto !p-0 !bg-[#e5e7ea] !border-[none]'>
                                                    <div className='!border-[1px] !border-[solid] !border-[#cccccc] relative table [border-spacing:0] bg-[#fff] rounded-[.25rem]'>
                                                        <DatePicker className='flex items-center h-[34px] border-none text-[.75rem] !bg-[#e5e7ea] w-full outline-none rounded-[.25rem] absolute overflow-hidden overflow-ellipsis whitespace-nowrap pl-[6px] text-[#555]' defaultValue={dayjs(new Date().toLocaleDateString(), 'DD/MM/YYYY')} format={dateFormat} onChange={onChange} />
                                                        <div className='h-[34px] relative align-middle whitespace-nowrap w-[1%] table-cell text-[0] [text-indent:9px!important] font-normal rounded-[.25rem] text-[#5c6873]'>
                                                            <button type='button' className='text-[#000] flex items-center justify-center h-full p-0 outline-[0] rounded-tl-[0] rounded-br-[4px] rounded-tr-[4px] rounded-bl-[0] !bg-[#c8ced2] !w-[40px]'>
                                                                <svg fill='black' width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M18 5V8H15V5H9V8H6V5H4V20H20V5H18ZM7 19H5V17H7V19ZM7 16H5V14H7V16ZM7 13H5V11H7V13ZM10 19H8V17H10V19ZM10 16H8V14H10V16ZM10 13H8V11H10V13ZM13 19H11V17H13V19ZM13 16H11V14H13V16ZM13 13H11V11H13V13ZM16 19H14V17H16V19ZM16 16H14V14H16V16ZM16 13H14V11H16V13ZM19 19H17V17H19V19ZM19 16H17V14H19V16ZM19 13H17V11H19V13Z" />
                                                                    <path d="M7 4H8V7H7V4Z" />
                                                                    <path d="M16 4H17V7H16V4Z" />
                                                                </svg>
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='!mt-0 pl-[calc(1.5rem*.5)] !pr-[18px] flex-[0_0_auto] w-[33.33333333%]'>
                                            <button className='w-full mb-[4px] text-[.875rem] px-[0.75rem] py-[0.375rem] rounded-[.25rem] bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] border-[1px] border-[#cccccc] text-[#ffffff] cursor-not-allowed pointer-events-none opacity-[.65]'>
                                                <strong className='text-[.875rem] !text-[#ffffff]'>Get Commission</strong>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className='col-span-12'>
                        <div className=' flex-[0_0_auto] w-full max-w-full'>
                            <div className='border-r-[1px] border-r-[#c8ced3] border-l-[1px] border-l-[#c8ced3] mb-[24px] border-t-[2px] border-t-[black] relative flex flex-col min-w-[0] text-[#212529] [word-wrap:break-word] bg-[#fff] bg-clip-border border-[1px] border-[solid] border-[#0000002d] rounded-[.375rem]'>
                                <div className='pt-[5px] px-[6px] pb-[6px] font-bold text-[14px] !bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] mb-0 border-b-[1px_solid_#0000002d] !text-[#ffffff] [word-wrap:break-word]'>
                                    <span className='relative top-px font-bold text-[0.875rem] !text-[#ffffff]'>Rolling Commission</span>
                                </div>
                                <div className='px-[10px] py-[20px] flex-auto'>
                                    <table className='overflow-x-auto [caption-side:bottom] text-[0.875rem] border-collapse w-full mb-4 text-[#212529] align-top border-[#dee2e6]'>
                                        <thead className='align-bottom text-[#212529]'>
                                            <tr className='border-[1_0] text-[#212529]'>
                                                <th className='px-[10px] py-[8px] border-[1px] border-[solid] border-[#c8ced3] border-b-[2px_solid_#c8ced3] border-b-2 align-bottom !bg-[#e0e6e6] !text-[#000000] [box-shadow:inset_0_0_0_9999px_transparent] !text-center min-w-[99px]'>Type</th>
                                                <th className='px-[10px] py-[8px] border-[1px] border-[solid] border-[#c8ced3] border-b-[2px_solid_#c8ced3] border-b-2 align-bottom !bg-[#e0e6e6] !text-[#000000] [box-shadow:inset_0_0_0_9999px_transparent] min-w-[99px] !text-center'>Total Stack</th>
                                                <th className='px-[10px] py-[8px] border-[1px] border-[solid] border-[#c8ced3] border-b-[2px_solid_#c8ced3] border-b-2 align-bottom !bg-[#e0e6e6] !text-[#000000] [box-shadow:inset_0_0_0_9999px_transparent] !text-center min-w-[99px]'>Total Commission</th>
                                            </tr>
                                        </thead>
                                        <tbody className='[vertical-align:inherit] text-[#212529]'>
                                            <tr className='border-[1_0]'>
                                                <td className='border-[1px] border-[solid] border-[#c8ced3] !px-[.5rem] !py-[.75rem] align-middle bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] !text-center text-[#212529]' colSpan={3}>No data!</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Mobile */}
            <div className='lg:hidden relative top-[56px]  text-[0.75rem]  flex flex-col min-w-[0] [word-wrap:break-word] rounded-[.25rem]'>
                <div className='grid grid-cols-12 mt-[22.5px] mb-[40px]'>
                    <div className='col-span-12'>
                        <div className=' flex-[0_0_auto] w-full'>
                            <div className='border-r-[1px] border-r-[#c8ced3] border-l-[1px] border-l-[#c8ced3] mb-[24px] rounded-[.25rem] pt-[10px]  pb-[0] w-full bg-[#e0e6e6] border-b-[1px] border-b-[#000501] border-t-[2px] border-t-[#000501] text-[#212529] [word-wrap:break-word] border-[1px] border-[solid] border-[#0000002d]'>
                                <div className='!p-0 flex-auto'>
                                    <div className='flex flex-wrap'>
                                        <div className='!pr-[17.5px] pl-[calc(1.5rem*.5)] !mb-4 flex-[0_0_auto] w-[50%] lg:w-[33.33333333%]'>
                                            <select id="datasourceSelect" className='pl-[.95rem] [text-indent:1px] appearance-none   block w-full h-[calc(1.5em+.75rem+2px)] px-[.75rem] py-[.375rem] text-[.875rem] font-normal leading-normal text-[#5c6873] border-[1px] border-[solid] border-[#ccc] rounded-[.25rem] [transition:border-color_.15s_ease-in-out,_box-shadow_.15s_ease-in-out] [word-wrap:normal]' style={{ background: '#fff url(https://aura444.com/Down-Arrow.696f11d0c8727f3b.svg) right 93% / 8% 12px no-repeat', backgroundPosition: 'right 0% bottom 45%' }}>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' disabled value="">Data Source</option>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' value="LIVE">LIVE DATA</option>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' value="BACKUP">BACKUP DATA</option>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' value="OLD">OLD DATA</option>
                                            </select>
                                        </div>

                                        <div className='!pr-[18px] pl-[calc(1.5rem*.5)] !mb-4 flex-[0_0_auto] w-[50%] lg:w-[33.33333333%]'>
                                            <select id="sportSelect" name='betType' className='pl-[.95rem] [text-indent:1px] appearance-none   block w-full h-[calc(1.5em+.75rem+2px)] px-[.75rem] py-[.375rem] text-[.875rem] font-normal leading-normal text-[#5c6873] border-[1px] border-[solid] border-[#ccc] rounded-[.25rem] [transition:border-color_.15s_ease-in-out,_box-shadow_.15s_ease-in-out]' style={{ background: '#fff url(https://aura444.com/Down-Arrow.696f11d0c8727f3b.svg) right 93% / 8% 12px no-repeat', backgroundPosition: 'right 0% bottom 45%' }}>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' value="" disabled="">Select Sport</option>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' value="4"> Cricket </option>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' value="2"> Tennis </option>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' value="66102"> Casino </option>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' value="1"> Soccer </option>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' value="7"> Horse Racing </option>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' value="4339"> Greyhound Racing </option>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' value="27979456"> Kabaddi </option>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' value="7522"> Basketball </option>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' value="2378961"> Politics </option>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' value="66101"> Virtual Sports </option>
                                                <option className='[text-indent:1px] text-[.875rem] leading-normal text-[#5c6873]' value="66104"> Lottery </option>
                                            </select>
                                        </div>

                                        <div className='!pr-[18px] pl-[calc(1.5rem*.5)] !mb-4 flex-[0_0_auto] w-[100%] lg:w-[33.33333333%]'>
                                            <div className='relative flex flex-wrap items-stretch w-[99%]'>
                                                <div className='block w-full h-[calc(1.5em+.75rem+2px)] text-[.875rem] font-normal leading-normal text-[#5c6873] rounded-[.25rem] [transition:border-color_.15s_ease-in-out,_box-shadow_.15s_ease-in-out] relative flex-auto !p-0 !bg-[#e5e7ea] !border-[none]'>
                                                    <div className='!border-[1px] !border-[solid] !border-[#cccccc] relative table [border-spacing:0] bg-[#fff] rounded-[.25rem]'>
                                                        {/* <input type="date" id="date-time" name="date-time" className='h-[34px] text-[.75rem] !bg-[#e5e7ea] w-full outline-none table-cell absolute overflow-hidden overflow-ellipsis whitespace-nowrap pl-[6px] text-[#555]' /> */}

                                                        <input
                                                            className='flex items-center h-[34px] border-none text-[14px] !bg-[#e5e7ea] w-full outline-none rounded-[.25rem] absolute overflow-hidden overflow-ellipsis whitespace-nowrap pl-[6px] text-[#555]'
                                                            type="text"
                                                            value={formattedFromDate}
                                                            onClick={toggleFromCalendar}
                                                            readOnly
                                                            placeholder="Select date"

                                                        />
                                                        {showFromCalendar && (
                                                            <div className="absolute z-[9999] calendar-container">
                                                                <Calendar onChange={(e) => handleFromDate(e)} value={fromDate || new Date()} calendarType="gregory" />
                                                            </div>
                                                        )}
                                                        {/* <DatePicker className='flex items-center h-[34px] border-none text-[.75rem] !bg-[#e5e7ea] w-full outline-none rounded-[.25rem] absolute overflow-hidden overflow-ellipsis whitespace-nowrap pl-[6px] text-[#555]' defaultValue={dayjs(new Date().toLocaleDateString(), 'DD/MM/YYYY')} format={dateFormat} onChange={onChange} /> */}

                                                        <div className='h-[34px] relative align-middle whitespace-nowrap w-[1%] table-cell text-[0] [text-indent:9px!important] font-normal rounded-[.25rem] text-[#5c6873]'>
                                                            <button type='button' className='text-[#000] flex items-center justify-center h-full p-0 outline-[0] rounded-tl-[0] rounded-br-[4px] rounded-tr-[4px] rounded-bl-[0] !bg-[#c8ced2] !w-[40px]'>
                                                                <svg fill='black' width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M18 5V8H15V5H9V8H6V5H4V20H20V5H18ZM7 19H5V17H7V19ZM7 16H5V14H7V16ZM7 13H5V11H7V13ZM10 19H8V17H10V19ZM10 16H8V14H10V16ZM10 13H8V11H10V13ZM13 19H11V17H13V19ZM13 16H11V14H13V16ZM13 13H11V11H13V13ZM16 19H14V17H16V19ZM16 16H14V14H16V16ZM16 13H14V11H16V13ZM19 19H17V17H19V19ZM19 16H17V14H19V16ZM19 13H17V11H19V13Z" />
                                                                    <path d="M7 4H8V7H7V4Z" />
                                                                    <path d="M16 4H17V7H16V4Z" />
                                                                </svg>
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='!pr-[18px] pl-[calc(1.5rem*.5)] !mb-[10px] flex-[0_0_auto] w-[100%] lg:w-[33.33333333%]'>
                                            <div className='relative flex flex-wrap items-stretch w-[99%]'>
                                                <div className='block w-full h-[calc(1.5em+.75rem+2px)] text-[.875rem] font-normal leading-normal text-[#5c6873] rounded-[.25rem] [transition:border-color_.15s_ease-in-out,_box-shadow_.15s_ease-in-out] relative flex-auto !p-0 !bg-[#e5e7ea] !border-[none]'>
                                                    <div className='!border-[1px] !border-[solid] !border-[#cccccc] relative table [border-spacing:0] bg-[#fff] rounded-[.25rem]'>

                                                        <input
                                                            className='flex items-center h-[34px] border-none text-[14px] !bg-[#e5e7ea] w-full outline-none rounded-[.25rem] absolute overflow-hidden overflow-ellipsis whitespace-nowrap pl-[6px] text-[#555]'
                                                            type="text"
                                                            value={formattedToDate}
                                                            onClick={toggleToCalendar}
                                                            readOnly
                                                            placeholder="Select date"

                                                        />
                                                        {showToCalendar && (
                                                            <div className="absolute z-[9999] calendar-container">
                                                                <Calendar onChange={(e) => handleToDate(e)} value={toDate || new Date()} calendarType="gregory" />
                                                            </div>
                                                        )}

                                                        {/* <DatePicker className='flex items-center h-[34px] border-none text-[.75rem] !bg-[#e5e7ea] w-full outline-none rounded-[.25rem] absolute overflow-hidden overflow-ellipsis whitespace-nowrap pl-[6px] text-[#555]' defaultValue={dayjs(new Date().toLocaleDateString(), 'DD/MM/YYYY')} format={dateFormat} onChange={onChange} /> */}
                                                        <div className='h-[34px] relative align-middle whitespace-nowrap w-[1%] table-cell text-[0] [text-indent:9px!important] font-normal rounded-[.25rem] text-[#5c6873]'>
                                                            <button type='button' className='text-[#000] flex items-center justify-center h-full p-0 outline-[0] rounded-tl-[0] rounded-br-[4px] rounded-tr-[4px] rounded-bl-[0] !bg-[#c8ced2] !w-[40px]'>
                                                                <svg fill='black' width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M18 5V8H15V5H9V8H6V5H4V20H20V5H18ZM7 19H5V17H7V19ZM7 16H5V14H7V16ZM7 13H5V11H7V13ZM10 19H8V17H10V19ZM10 16H8V14H10V16ZM10 13H8V11H10V13ZM13 19H11V17H13V19ZM13 16H11V14H13V16ZM13 13H11V11H13V13ZM16 19H14V17H16V19ZM16 16H14V14H16V16ZM16 13H14V11H16V13ZM19 19H17V17H19V19ZM19 16H17V14H19V16ZM19 13H17V11H19V13Z" />
                                                                    <path d="M7 4H8V7H7V4Z" />
                                                                    <path d="M16 4H17V7H16V4Z" />
                                                                </svg>
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='!mt-0 pl-[calc(1.5rem*.5)] pb-1 !pr-[18px] flex-[0_0_auto] w-[50%] lg:w-[33.33333333%]'>
                                            <button className='pb-1 w-full mb-[4px] text-[.875rem] px-[0.75rem] py-[0.375rem] rounded-[.25rem] bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] border-[1px] border-[#cccccc] text-[#ffffff] cursor-not-allowed pointer-events-none opacity-[.65]'>
                                                <strong className='text-[.875rem] !text-[#ffffff]'>Get Commission</strong>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className='col-span-12'>
                        <div className=' flex-[0_0_auto] w-full max-w-full'>
                            <div className='border-r-[1px] border-r-[#c8ced3] border-l-[1px] border-l-[#c8ced3] mb-[24px] border-t-[2px] border-t-[black] relative flex flex-col min-w-[0] text-[#212529] [word-wrap:break-word] bg-[#fff] bg-clip-border border-[1px] border-[solid] border-[#0000002d] rounded-[.375rem]'>
                                <div className='pt-[5px] px-[6px] pb-[6px] font-bold text-[14px] !bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] mb-0 border-b-[1px_solid_#0000002d] !text-[#ffffff] [word-wrap:break-word]'>
                                    <span className='relative top-px font-bold text-[0.875rem] !text-[#ffffff]'>Rolling Commission</span>
                                </div>
                                <div className='px-[10px] py-[20px] flex-auto'>
                                    <table className='overflow-x-auto [caption-side:bottom] text-[0.875rem] border-collapse w-full mb-4 text-[#212529] align-top border-[#dee2e6]'>
                                        <thead className='align-bottom text-[#212529]'>
                                            <tr className='border-[1_0] text-[#212529]'>
                                                <th className='px-[10px] py-[8px] border-[1px] border-[solid] border-[#c8ced3] border-b-[2px_solid_#c8ced3] border-b-2 align-bottom !bg-[#e0e6e6] !text-[#000000] [box-shadow:inset_0_0_0_9999px_transparent] !text-center min-w-[99px]'>Type</th>
                                                <th className='px-[10px] py-[8px] border-[1px] border-[solid] border-[#c8ced3] border-b-[2px_solid_#c8ced3] border-b-2 align-bottom !bg-[#e0e6e6] !text-[#000000] [box-shadow:inset_0_0_0_9999px_transparent] min-w-[99px] !text-center'>Total Stack</th>
                                                <th className='px-[10px] py-[8px] border-[1px] border-[solid] border-[#c8ced3] border-b-[2px_solid_#c8ced3] border-b-2 align-bottom !bg-[#e0e6e6] !text-[#000000] [box-shadow:inset_0_0_0_9999px_transparent] !text-center min-w-[99px]'>Total Commission</th>
                                            </tr>
                                        </thead>
                                        <tbody className='[vertical-align:inherit] text-[#212529]'>
                                            <tr className='border-[1_0]'>
                                                <td className='border-[1px] border-[solid] border-[#c8ced3] !px-[.5rem] !py-[.75rem] align-middle bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] !text-center text-[#212529]' colSpan={3}>No data!</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default RollingCommission;
