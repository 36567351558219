export default {
    apiUrl: 'https://digital.allcasino.in:3000/',
    // apiUrl: 'http://127.0.0.1:3000/',
    bUrl: 'http://139.162.213.154:3000/',
    userTypes: ['Admin', 'Super Admin', 'Sub Admin', 'Super Master', 'Sub Master', 'Master', 'User'],
    userTypesAlias: {
        'admin': 'Admin', 'super_admin': 'Super Admin', 'sub_admin': 'Sub Admin', 'super_master': 'Super Master', 'sub_master': 'Sub Master', 'master': 'Master', 'user': 'User'
    },
    sitecodes: 'P24',
    // sitecodes: 'P34',

    // videoUrl: {
    //     '7ud': 'https://route53.casinovid.in/dvideo/lucky7a/',
    //     'ab': 'https://route53.casinovid.in/dvideo/andarbahar/',
    //     'ltp': 'https://route53.casinovid.in/dvideo/onedaytp/',
    //     't20': 'https://route53.casinovid.in/dvideo/teen20',
    //     'dt20': 'https://route53.casinovid.in/dvideo/dragontiger20/',
    //     'aaa': 'https://route53.casinovid.in/dvideo/amar',
    //     '32c': 'https://route53.casinovid.in/dvideo/32b/',
    // }
    videoUrl: {
        '7ud': 'https://tv.fireflybet.in/index.html?id=3058',
        'ab': 'https://route53.casinovid.in/dvideo/andarbahar/',
        'ltp': 'https://backlayexchange.com/new/oneday/',
        't20': 'https://backlayexchange.com/new/2020/',
        'dt20': 'https://tv.fireflybet.in/index.html?id=3059/',
        'aaa': 'https://shroute.casinovid.in/diamondvideo/dot.php?id=3056',
        '32c': 'https://shroute.casinovid.in/diamondvideo/dot.php?id=3034',
    }
    // videoUrl: {
    //     '7ud': 'http://45.56.112.18/?id=3081',
    //     'ab': 'http://45.56.112.18/?id=3082',
    //     'ltp': 'http://45.56.112.18/?id=3080',
    //     't20': 'http://45.56.112.18/?id=3085',
    //     'dt20': 'http://45.56.112.18/?id=3087',
    //     'aaa': 'http://45.56.112.18/?id=3086',
    //     '32c': 'http://45.56.112.18/?id=3084',
    // }
    // http://139.162.213.154/dvideo/teen20.html
    ,
    marketSarket: "http://marketsarket.in:3002/",
    casino_type_id: {
        '7ud': '1001',
        'ab': '1002',
        'ltp': '1003',
        't20': '1004',
        'dt20': '1005',
        'aaa': '1006',
        '32c': '1007'
    },
    casino_event_id: {
        '7ud': '98789',
        'ab': '87564',
        'ltp': '56767',
        't20': '56768',
        'dt20': '98790',
        'aaa': '98791',
        '32c': '56967'
    },
    site_url: 'https://skyexchangeart.in/'

}
// export const SOCKET_URL = 'https://digitalws.lce247.com:8040/';
export const SOCKET_URL = 'wss://ws.maza247.vip:7071/';

export const DEFAULT_CURRENCY = 'PBU';

export const SITE_URL = 'https://skyexchangeart.in/';

