import '../../App.css'
import { useState, useEffect } from 'react';
import { LuClock9 } from "react-icons/lu";
import { SlScreenDesktop } from "react-icons/sl";
import { Link, useParams } from 'react-router-dom';
import { signal } from "@preact/signals-react";
import axios from 'axios';
import Appconfig from '../../config/config'
import TableRow from '../TableRow';
import MobileTableRow from '../mobileTableRow';
export const eventId = signal("");
function Cricket() {
    const { id } = useParams();
    // eventId.value = id;
    const userInfo = JSON.parse(localStorage.getItem('userdata'))
    const [events, setEvents] = useState([])
    const [allEvents, setAllEvents] = useState([])


    function getEvents() {
        var data = JSON.stringify({
            user_id: userInfo ? userInfo._id : "",
            event_type_id: 0,
        });
        let url = "get-dashboard-data-with-user-id";
        if (!userInfo) {
            url = "get-dashboard-data-without-user-id";
        }
        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}eventsDashboard/${url}`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                if (response.data.result == 1) {
                    // console.log('response.data:', response.data?.resultData[0]?.competitions[0].events)
                    setAllEvents(response.data?.resultData[0]?.competitions[0].events)

                }
                else {
                    // setBalance(response.data.resultData.balance)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        getEvents()
    }, [])

    useEffect(() => {
        if (allEvents.length > 0) {
            const tempEvents = allEvents.filter(event => event.event_type == 4)
            // const inPlay = allEvents.filter(event => event.is_inplay == 'True')
            setEvents(tempEvents)
        }
    }, [allEvents])
    const formatDateString = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}-${month}-${year} ${hours}:${minutes}`;
    };
    return (
        <>
            {/* Desktop */}
            {!events.length > 0 && (
                <div id="poker_loading" className="relative !z-[99999] loading-wrap w-[40vw] h-[24vw] text-[3.2vw] rounded-[1.33333vw] [box-shadow:0_.8vw_2.66667vw_0_rgba(0,_0,_0,_.5)]" >
                    <div className="loading w-[18.66667vw] h-[8.5vw] ]">
                        <div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <p>Loading...</p>
                </div>
            )}
            {events.length > 0 && (
                <table className="hidden lg:inline-table w-full">
                    <thead className="#dddcd6 bg-[#dddcd6] ">
                        <tr>
                            <th scope="col" className="text-[0.688rem] "></th>
                            <th scope="col" className="text-[0.688rem] "></th>
                            <th scope="col" className="text-[0.688rem] ">1</th>
                            <th scope="col" className="text-[0.688rem] ">X</th>
                            <th scope="col" className="text-[0.688rem] ">2</th>
                            <th scope="col" className="text-[0.688rem] "></th>
                        </tr>
                    </thead>
                    <tbody className='bg-[linear-gradient(-180deg,_#ffffff_0%,_#ffffff_100%)]'>
                        {events.map((event, index) => (
                            <TableRow key={index} row={event} />
                        ))}
                    </tbody>
                </table>
            )}
            {/* Mobile */}
            {events.length > 0 && (
                <table className="lg:hidden w-full">
                    <tbody className='bg-[linear-gradient(-180deg,_#ffffff_0%,_#ffffff_100%)]'>
                        {events.map((event, index) => (
                            <MobileTableRow key={index} row={event} />
                        ))}

                    </tbody>
                </table>
            )}
        </>
    );
}

export default Cricket;
