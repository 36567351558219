import '../../App.css'
import { Fragment, useEffect, useRef, useState, useContext } from 'react';
import { Modal } from 'antd';
import { LuClock9 } from "react-icons/lu";
import { SlScreenDesktop } from "react-icons/sl";
import { FaCircle } from "react-icons/fa";
import Winner from '../../components/winner';
import MatchOdds from '../../components/matchOdds';
import TiedMatch from '../../components/tiedMatch';
import Bookmaker from '../../components/bookmaker';
import WinTheMatch from '../../components/win-the-match';
import Fancy from '../../components/fancy';
import Sportsbook from '../../components/sportsbook';
import { json, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Appconfig from '../../config/config'
import { WebSocketContext } from '../../context/websocket';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
function Casino() {
    const navigate = useNavigate();
    const { casino_id } = useParams();
    const userInfo = JSON.parse(localStorage.getItem('userdata'))
    const [loadCasino, setLoadCasino] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        setTimeout(() => {
            setLoadCasino(false);
        }, 1000)
    })
    const [seconds, setSeconds] = useState(45);
    useEffect(() => {
        if (seconds > 0) {
            const interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
            return () => clearInterval(interval);
        } else {
            setSeconds(45);
        }
    }, [seconds]);


    return (
        <>
            {loadCasino ?
                <div className='block absolute w-full left-0 top-0 h-screen overflow-hidden'>
                    <div className='fixed top-0 relative w-full h-screen flex justify-center items-center bg-[#000000] z-[99999]'>
                        <img src='/loader.gif' className='w-[350px]' />
                    </div>
                </div>
                :
                <div className='grid grid-cols-12 relative top-[56px] lg:top-0'>
                    <div className='col-span-12 relative flex'>
                        <video id="remoteVideo" width="100%" height="100%" autoplay="" playsinline="" muted="" style={{ backgroundColor: 'black', backgroundSize: 'contain' }}></video>
                        <div className='absolute bg-[rgba(0,_0,_0,_.4)] h-auto left-[0] content-[""] p-[3px]'>
                            <div className='flex flex-col'>
                                <div className="font-bold text-[#fff] !text-[10px]">RID: 9102906065327</div>
                                <div className='flex flex-col'>
                                    <span className='leading-[9px] font-bold mb-0 mt-0 uppercase w-full !text-[12.5px] pb-[3px] pt-[3px] text-[#ffffff]'>PLAYER A</span>
                                    <div className='flex'>
                                        <img className='w-[23px] h-auto mr-[3px]' src="/cards/0.png"></img>
                                        <img className='w-[23px] h-auto mr-[3px]' src="/cards/C9_.png"></img>
                                        <img className='w-[23px] h-auto mr-[3px]' src="/cards/D10_.png"></img>
                                    </div>
                                </div>
                                <div className='flex flex-col pt-[3px]'>
                                    <span className='leading-[9px] font-bold mb-0 mt-0 uppercase w-full !text-[12.5px] pb-[3px] pt-[3px] text-[#ffffff]'>PLAYER B</span>
                                    <div className='flex'>
                                        <img className='w-[23px] h-auto mr-[3px]' src="/cards/SA_.png"></img>
                                        <img className='w-[23px] h-auto mr-[3px]' src="/cards/H3_.png"></img>
                                        <img className='w-[23px] h-auto mr-[3px]' src="/cards/DK_.png"></img>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="winnerOverlay absolute h-auto bottom-2/4 ml-auto mr-auto p-[5px] w-full flex justify-center">
                            <div className="mini-result-new active border-[0] border-[solid] mb-0 text-[#000] bg-[linear-gradient(-180deg,_#fd8f3b_0%,_#fd3523_100%)] w-auto text-[14px] p-[6px] uppercase rounded-tl-[16px] rounded-br-[16px] rounded-tr-[2px] rounded-bl-[2px] min-h-[20px] [transition:all_.5s_cubic-bezier(.168,-.885,.3,2)_.5s] scale-0 scale-100">
                                <span > PLAYER B win </span>
                            </div>
                            <span className='absolute' >
                                <canvas width="100vw" height="100vh">
                                </canvas>
                            </span>
                        </div>


                        <div className="timer-overlay  ">
                            <div className='setting-btn text-white text-[1rem]'>
                                <i className="fa fa-cog fa-lg" style={{ color: '#ff8100' }}></i>
                            </div>
                            <div className="timer-group">
                                <div className="timer minute">
                                    <div className="hand">
                                        <span className="">
                                        </span>
                                    </div>
                                    <div className="hand">
                                        <span className="">
                                        </span>
                                    </div>
                                </div>
                                <div className="face">
                                    <p id="lazy" className="">{seconds}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-span-12 h-[calc(101vh-40vh)] lg:h-auto overflow-auto'>
                        <div className='grid grid-cols-12'>
                            <div className='col-span-12 relative flex flex-col [word-wrap:break-word] border-[1px] border-[#0000002d] rounded-[.375rem]'>
                                <span className='px-[5px] py-[2px] text-[12px] font-bold bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] text-[#ffffff] flex justify-between items-center rounded-t-[.375rem]'>
                                    <span className='text-[.75rem] font-bold text-[#ffffff]'>WINNER</span>
                                    <span className='hidden lg:block text-[.75rem] font-bold text-[#ffffff]'>Min/Max: 100 - 500000 </span>
                                    <span className='block lg:hidden'>
                                        <i _ngcontent-ng-c4116347132="" data-bs-toggle="dropdown" aria-expanded="false" class="fa fa-info-circle ml-2" data-bs-target="#multiCollapseExample9102906145759" id="#collapse129102906145759"></i>
                                    </span>

                                </span>
                                <div className="flex-[1_0_0%]">
                                    <div className="" id="multiCollapseExample9102906131109">
                                        <div className="relative p-0 m-0 bg-[linear-gradient(180deg,_#ffffff_,_#ffffff_42%)] rounded-bl-[6px]">
                                            <div className="suspended-casino">
                                                <span class="stats-text">Suspended</span>
                                            </div>
                                            <div className="flex odd-row playerABgTie">
                                                <div class="flex-[1_0_0%] flex flex-col items-center justify-center">
                                                    <div className="pb-[.25rem]">
                                                        <span class="flex items-center justify-center text-[.75rem] font-bold text-[#23282c]"> PLAYER A </span>
                                                    </div>
                                                    <div appratehighlight="" className="w-[150px] rounded-[5px] py-[2px] [box-shadow:0_2px_7px_1px_#67828be6] bg-[#72bbef] cursor-pointer">
                                                        <a className="flex  flex-col items-center justify-center no-underline font-semibold text-[#000]"
                                                        >
                                                            1.98
                                                            <span className="text-[11px] font-normal flex justify-center">1353664</span>
                                                        </a>
                                                    </div>
                                                    <div class="d-flex align-items-center p-0">
                                                    </div>
                                                </div>
                                                <div class="flex-[1_0_0%] flex flex-col items-center justify-center">
                                                    <div className="pb-[.25rem]">
                                                        <span class="flex items-center justify-center text-[.75rem] font-bold text-[#23282c]"> PLAYER B </span>
                                                    </div>
                                                    <div appratehighlight="" className="w-[150px] rounded-[5px] py-[2px] [box-shadow:0_2px_7px_1px_#67828be6] bg-[#72bbef] cursor-pointer">
                                                        <a className="flex  flex-col items-center justify-center no-underline font-semibold text-[#000]"
                                                        >
                                                            1.98
                                                            <span className="text-[11px] font-normal flex justify-center">1353664</span>
                                                        </a>
                                                    </div>
                                                    <div class="d-flex align-items-center p-0">
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div>
                                        <div className={`align-middle text-center text-[.75rem] px-[0] py-[3px] font-bold border-t-[none]  bg-[linear-gradient(180deg,_#BEDDF4_0%,_#D4E8F8_100%)] border-b-[1px_solid_#7e97a7]`}>
                                            <div className='text-center text-[12px]'>
                                                <div className='overflow-hidden text-center text-[.75rem] font-bold'>
                                                    <div className={`bg-[#beddf4] text-center text-[.75rem] font-bold`}>
                                                        <div className='w-full pr-[calc(var(--bs-gutter-x)* .5)] pl-[calc(var(--bs-gutter-x)* .5)] mr-auto ml-auto text-center text-[.75rem] font-bold'>
                                                            <div className='flex relative !pl-[.5rem] !pb-0 !pr-[.5rem] text-center text-[.75rem] font-bold'>
                                                                <div className='!pb-0 !p-[.25rem] flex-[1_0_0%] w-full mt-0'>
                                                                    <button className='leading-[36px] pt-0 pb-0 w-full bg-[#f9f9f9] border-[1px] border-[solid] border-[#333] rounded-[4px] !text-[#000000] !font-bold !text-[0.813rem] !h-[38px] !ml-[0] !mr-[5px] !my-[0] text-center'
                                                                    > Cancel </button>
                                                                </div>
                                                                <div className='!p-[.25rem] flex-[1_0_0%] w-full max-w-full mt-0'>
                                                                    <button className='bg-[linear-gradient(-180deg,#fff_0,#eee_89%)] h-[40px] w-[40px] leading-[28px] float-left text-[1rem] text-[#1f72ac] p-[10px] rounded-bl-[7px] rounded-tl-[7px] border-[1px]  border-[#aaaaaa] content-[""] bg-no-repeat bg-contain flex absolute items-center justify-center rounded-none'
                                                                    >
                                                                        <span className='content-[""] bg-no-repeat bg-contain flex w-[20px] absolute h-[15px] text-[1.313rem] items-center justify-center leading-[28px] text-[#1f72ac]'>
                                                                            <svg className='w-[19px] h-[19px]' version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="19px" height="19px" viewBox="0 0 19 19" enableBackground="new 0 0 19 19" xmlSpace="preserve">
                                                                                <path fill="currentColor" d="M8.056,10.838H0.409V8.091h7.647 M2,8.091h16.591v2.747H8"></path>
                                                                            </svg>
                                                                        </span>
                                                                    </button>
                                                                    <input className='bg-[#ffffff] text-center border-t-[1px] border-t-[#aaaaaa] border-b-[1px] border-b-[#aaaaaa] border-l-[0px] border-r-[0px] w-[59%] !h-[40px] !text-[#1e1e1e] !font-bold !text-[0.875rem] rounded-none outline-none' readOnly type="text" placeholder="0" />
                                                                    <button className='bg-[linear-gradient(-180deg,#fff_0,#eee_89%)] h-[40px] w-[40px] leading-[28px] text-[1rem] text-[#1f72ac] p-[10px] bg-no-repeat bg-contain inline-flex border-[1px]  border-[#aaaaaa] absolute !rounded-tl-[0] !rounded-br-[7px] !rounded-tr-[7px] !rounded-bl-[0] !float-right'
                                                                    >
                                                                        <span className='bg-no-repeat bg-contain inline-flex w-[20px] absolute h-[19px] text-[1.188rem] leading-[28px] text-[#1f72ac]'>
                                                                            <svg className='w-[19px] h-[19px]' version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="19px" height="19px" viewBox="0 0 19 19" enableBackground="new 0 0 19 19" xmlSpace="preserve">
                                                                                <path fill="currentColor" d="M10.946,0.127v7.964h7.646v2.747h-7.646v8.035h-2.89v-8.035H0.409V8.091h7.647V0.127H10.946z"></path>
                                                                            </svg>
                                                                        </span>
                                                                    </button>
                                                                </div>

                                                                <div className='!p-[.25rem] flex-[1_0_0%] w-full max-w-full mt-0'>
                                                                    <button className='bg-[linear-gradient(-180deg,#fff_0,#eee_89%)] h-[40px] w-[40px] leading-[28px] float-left text-[1rem] text-[#1f72ac] p-[10px] rounded-bl-[7px] rounded-tl-[7px] border-[1px]  border-[#aaaaaa] content-[""] bg-no-repeat bg-contain flex absolute items-center justify-center rounded-none'
                                                                    >
                                                                        <span className='content-[""] bg-no-repeat bg-contain flex w-[20px] absolute h-[15px] text-[1.313rem] items-center justify-center leading-[28px] text-[#1f72ac]'>
                                                                            <svg className='w-[19px] h-[19px]' version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="19px" height="19px" viewBox="0 0 19 19" enableBackground="new 0 0 19 19" xmlSpace="preserve">
                                                                                <path fill="currentColor" d="M8.056,10.838H0.409V8.091h7.647 M2,8.091h16.591v2.747H8"></path>
                                                                            </svg>
                                                                        </span>
                                                                    </button>
                                                                    <input className='bg-[#ffffff] text-center border-t-[1px] border-t-[#aaaaaa] border-b-[1px] border-b-[#aaaaaa] border-l-[0px] border-r-[0px] w-[59%] !h-[40px] !text-[#1e1e1e] !font-bold !text-[0.875rem] rounded-none outline-none' readOnly type="text" placeholder="0" ></input>
                                                                    <button className='bg-[linear-gradient(-180deg,#fff_0,#eee_89%)] h-[40px] w-[40px] leading-[28px] text-[1rem] text-[#1f72ac] p-[10px] bg-no-repeat bg-contain inline-flex border-[1px]  border-[#aaaaaa] absolute !rounded-tl-[0] !rounded-br-[7px] !rounded-tr-[7px] !rounded-bl-[0] !float-right'
                                                                    >
                                                                        <span className='bg-no-repeat bg-contain inline-flex w-[20px] absolute h-[19px] text-[1.188rem] leading-[28px] text-[#1f72ac]'>
                                                                            <svg className='w-[19px] h-[19px]' version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="19px" height="19px" viewBox="0 0 19 19" enableBackground="new 0 0 19 19" xmlSpace="preserve">
                                                                                <path fill="currentColor" d="M10.946,0.127v7.964h7.646v2.747h-7.646v8.035h-2.89v-8.035H0.409V8.091h7.647V0.127H10.946z"></path>
                                                                            </svg>
                                                                        </span>
                                                                    </button>
                                                                </div>


                                                                <div className='!pb-0 !p-[.25rem] flex-[1_0_0%] w-full max-w-full mt-0 text-center text-[.75rem] font-bold'>
                                                                    <button className={`!bg-[linear-gradient(-180deg,_#315195_0%,_#14213D_100%)] text-[1rem]  px-[0.75rem] py-[0.375rem] text-[#ffffff] border-[#000000]  w-full border-[1px] border-[#000000] rounded-[5px] font-bold cursor-not-allowed pointer-events-none opacity-[.65]`}
                                                                    >
                                                                        Place Bet
                                                                    </button>
                                                                </div>
                                                            </div>


                                                            <div className={`border-t-[1px] border-t-[#7dbbe9]  relative !pb-0 !pt-0 !p-[.5rem] flex flex-wrap`}>
                                                                <div className='!p-[.25rem] flex-[1_0_0%] w-full max-w-full'>
                                                                    <button className='bg-[#f9f9f9] border-[1px] border-[#333] rounded-[4px] !text-[#000000] !font-normal !leading-[18px] !text-[0.813rem] !h-[31px] !ml-[0] !mr-[5px] !my-[0] text-center w-full' >100</button>
                                                                </div>

                                                                <div className='!p-[.25rem] flex-[1_0_0%] w-full max-w-full'>
                                                                    <button className='bg-[#f9f9f9] border-[1px] border-[#333] rounded-[4px] !text-[#000000] !font-normal !leading-[18px] !text-[0.813rem] !h-[31px] !ml-[0] !mr-[5px] !my-[0] text-center w-full' >200</button>
                                                                </div>
                                                                <div className='!p-[.25rem] flex-[1_0_0%] w-full max-w-full'>
                                                                    <button className='bg-[#f9f9f9] border-[1px] border-[#333] rounded-[4px] !text-[#000000] !font-normal !leading-[18px] !text-[0.813rem] !h-[31px] !ml-[0] !mr-[5px] !my-[0] text-center w-full' >500</button>
                                                                </div>
                                                                <div className='!p-[.25rem] flex-[1_0_0%] w-full max-w-full'>
                                                                    <button className='bg-[#f9f9f9] border-[1px] border-[#333] rounded-[4px] !text-[#000000] !font-normal !leading-[18px] !text-[0.813rem] !h-[31px] !ml-[0] !mr-[5px] !my-[0] text-center w-full' >5000</button>
                                                                </div>
                                                                <div className='!p-[.25rem] flex-[1_0_0%] w-full max-w-full'>
                                                                    <button className='bg-[#f9f9f9] border-[1px] border-[#333] rounded-[4px] !text-[#000000] !font-normal !leading-[18px] !text-[0.813rem] !h-[31px] !ml-[0] !mr-[5px] !my-[0] text-center w-full' >10000</button>
                                                                </div>
                                                                <div className='!p-[.25rem] flex-[1_0_0%] w-full max-w-full'>
                                                                    <button className='bg-[#f9f9f9] border-[1px] border-[#333] rounded-[4px] !text-[#000000] !font-normal !leading-[18px] !text-[0.813rem] !h-[31px] !ml-[0] !mr-[5px] !my-[0] text-center w-full' >25000</button>
                                                                </div>
                                                                <div className='!p-[.25rem] flex-[1_0_0%] w-full max-w-full'>
                                                                    <button className='bg-[#f9f9f9] border-[1px] border-[#333] rounded-[4px] !text-[#000000] !font-normal !leading-[18px] !text-[0.813rem] !h-[31px] !ml-[0] !mr-[5px] !my-[0] text-center w-full' >50000</button>
                                                                </div>
                                                                <div className='!p-[.25rem] flex-[1_0_0%] w-full max-w-full'>
                                                                    <button className='bg-[#f9f9f9] border-[1px] border-[#333] rounded-[4px] !text-[#000000] !font-normal !leading-[18px] !text-[0.813rem] !h-[31px] !ml-[0] !mr-[5px] !my-[0] text-center w-full' >100000</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-span-12 relative flex flex-col [word-wrap:break-word] border-[1px] border-[#0000002d] rounded-[.375rem]'>
                                <span className='px-[5px] py-[2px] text-[12px] font-bold bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] text-[#ffffff] flex justify-between items-center rounded-t-[.375rem]'>
                                    <span className='text-[.75rem] font-bold text-[#ffffff]'>PAIR ( DUBBLE ) 1:4</span>
                                    <span className='hidden lg:block text-[.75rem] font-bold text-[#ffffff]'>Min/Max: 100 - 500000 </span>
                                    <span className='block lg:hidden'>
                                        <i _ngcontent-ng-c4116347132="" data-bs-toggle="dropdown" aria-expanded="false" class="fa fa-info-circle ml-2" data-bs-target="#multiCollapseExample9102906145759" id="#collapse129102906145759"></i>
                                    </span>
                                </span>
                                <div className="flex-[1_0_0%]">
                                    <div className="" id="multiCollapseExample9102906131109">
                                        <div className="relative p-0 m-0 bg-[linear-gradient(180deg,_#ffffff_,_#ffffff_42%)] rounded-bl-[6px]">
                                            <div className="suspended-casino">
                                                <span class="stats-text">Suspended</span>
                                            </div>
                                            <div className="flex odd-row playerABgTie">
                                                <div class="flex-[1_0_0%] flex flex-col items-center justify-center">
                                                    <div className="pb-[.25rem]">
                                                        <span class="flex items-center justify-center text-[.75rem] font-bold text-[#23282c]"> PLAYER A </span>
                                                    </div>
                                                    <div appratehighlight="" className="w-[150px] rounded-[5px] py-[2px] [box-shadow:0_2px_7px_1px_#67828be6] bg-[#72bbef] cursor-pointer">
                                                        <a className="flex  flex-col items-center justify-center no-underline font-semibold text-[#000]"
                                                        >
                                                            1.98
                                                            <span className="text-[11px] font-normal flex justify-center">1353664</span>
                                                        </a>
                                                    </div>
                                                    <div class="d-flex align-items-center p-0">
                                                    </div>
                                                </div>
                                                <div class="flex-[1_0_0%] flex flex-col items-center justify-center">
                                                    <div className="pb-[.25rem]">
                                                        <span class="flex items-center justify-center text-[.75rem] font-bold text-[#23282c]"> PLAYER B </span>
                                                    </div>
                                                    <div appratehighlight="" className="w-[150px] rounded-[5px] py-[2px] [box-shadow:0_2px_7px_1px_#67828be6] bg-[#72bbef] cursor-pointer">
                                                        <a className="flex  flex-col items-center justify-center no-underline font-semibold text-[#000]"
                                                        >
                                                            1.98
                                                            <span className="text-[11px] font-normal flex justify-center">1353664</span>
                                                        </a>
                                                    </div>
                                                    <div class="d-flex align-items-center p-0">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-span-12 relative flex flex-col [word-wrap:break-word] border-[1px] border-[#0000002d] rounded-[.375rem]'>
                                <span className='px-[5px] py-[2px] text-[12px] font-bold bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] text-[#ffffff] flex justify-between items-center rounded-t-[.375rem]'>
                                    <span className='text-[.75rem] font-bold text-[#ffffff]'>FLUSH ( COLOR ) 1:8</span>
                                    <span className='hidden lg:block text-[.75rem] font-bold text-[#ffffff]'>Min/Max: 100 - 500000 </span>
                                    <span className='block lg:hidden'>
                                        <i _ngcontent-ng-c4116347132="" data-bs-toggle="dropdown" aria-expanded="false" class="fa fa-info-circle ml-2" data-bs-target="#multiCollapseExample9102906145759" id="#collapse129102906145759"></i>
                                    </span>
                                </span>
                                <div className="flex-[1_0_0%]">
                                    <div className="" id="multiCollapseExample9102906131109">
                                        <div className="relative p-0 m-0 bg-[linear-gradient(180deg,_#ffffff_,_#ffffff_42%)] rounded-bl-[6px]">
                                            <div className="suspended-casino">
                                                <span class="stats-text">Suspended</span>
                                            </div>
                                            <div className="flex odd-row playerABgTie">
                                                <div class="flex-[1_0_0%] flex flex-col items-center justify-center">
                                                    <div className="pb-[.25rem]">
                                                        <span class="flex items-center justify-center text-[.75rem] font-bold text-[#23282c]"> PLAYER A </span>
                                                    </div>
                                                    <div appratehighlight="" className="w-[150px] rounded-[5px] py-[2px] [box-shadow:0_2px_7px_1px_#67828be6] bg-[#72bbef] cursor-pointer">
                                                        <a className="flex  flex-col items-center justify-center no-underline font-semibold text-[#000]"
                                                        >
                                                            1.98
                                                            <span className="text-[11px] font-normal flex justify-center">1353664</span>
                                                        </a>
                                                    </div>
                                                    <div class="d-flex align-items-center p-0">
                                                    </div>
                                                </div>
                                                <div class="flex-[1_0_0%] flex flex-col items-center justify-center">
                                                    <div className="pb-[.25rem]">
                                                        <span class="flex items-center justify-center text-[.75rem] font-bold text-[#23282c]"> PLAYER B </span>
                                                    </div>
                                                    <div appratehighlight="" className="w-[150px] rounded-[5px] py-[2px] [box-shadow:0_2px_7px_1px_#67828be6] bg-[#72bbef] cursor-pointer">
                                                        <a className="flex  flex-col items-center justify-center no-underline font-semibold text-[#000]"
                                                        >
                                                            1.98
                                                            <span className="text-[11px] font-normal flex justify-center">1353664</span>
                                                        </a>
                                                    </div>
                                                    <div class="d-flex align-items-center p-0">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-span-12 relative flex flex-col [word-wrap:break-word] border-[1px] border-[#0000002d] rounded-[.375rem]'>
                                <span className='px-[5px] py-[2px] text-[12px] font-bold bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] text-[#ffffff] flex justify-between items-center rounded-t-[.375rem]'>
                                    <span className='text-[.75rem] font-bold text-[#ffffff]'>STRAIGHT ( ROWN ) 1:14</span>
                                    <span className='hidden lg:block text-[.75rem] font-bold text-[#ffffff]'>Min/Max: 100 - 500000 </span>
                                    <span className='block lg:hidden'>
                                        <i _ngcontent-ng-c4116347132="" data-bs-toggle="dropdown" aria-expanded="false" class="fa fa-info-circle ml-2" data-bs-target="#multiCollapseExample9102906145759" id="#collapse129102906145759"></i>
                                    </span>
                                </span>
                                <div className="flex-[1_0_0%]">
                                    <div className="" id="multiCollapseExample9102906131109">
                                        <div className="relative p-0 m-0 bg-[linear-gradient(180deg,_#ffffff_,_#ffffff_42%)] rounded-bl-[6px]">
                                            <div className="suspended-casino">
                                                <span class="stats-text">Suspended</span>
                                            </div>
                                            <div className="flex odd-row playerABgTie">
                                                <div class="flex-[1_0_0%] flex flex-col items-center justify-center">
                                                    <div className="pb-[.25rem]">
                                                        <span class="flex items-center justify-center text-[.75rem] font-bold text-[#23282c]"> PLAYER A </span>
                                                    </div>
                                                    <div appratehighlight="" className="w-[150px] rounded-[5px] py-[2px] [box-shadow:0_2px_7px_1px_#67828be6] bg-[#72bbef] cursor-pointer">
                                                        <a className="flex  flex-col items-center justify-center no-underline font-semibold text-[#000]"
                                                        >
                                                            1.98
                                                            <span className="text-[11px] font-normal flex justify-center">1353664</span>
                                                        </a>
                                                    </div>
                                                    <div class="d-flex align-items-center p-0">
                                                    </div>
                                                </div>
                                                <div class="flex-[1_0_0%] flex flex-col items-center justify-center">
                                                    <div className="pb-[.25rem]">
                                                        <span class="flex items-center justify-center text-[.75rem] font-bold text-[#23282c]"> PLAYER B </span>
                                                    </div>
                                                    <div appratehighlight="" className="w-[150px] rounded-[5px] py-[2px] [box-shadow:0_2px_7px_1px_#67828be6] bg-[#72bbef] cursor-pointer">
                                                        <a className="flex  flex-col items-center justify-center no-underline font-semibold text-[#000]"
                                                        >
                                                            1.98
                                                            <span className="text-[11px] font-normal flex justify-center">1353664</span>
                                                        </a>
                                                    </div>
                                                    <div class="d-flex align-items-center p-0">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-span-12 relative flex flex-col [word-wrap:break-word] border-[1px] border-[#0000002d] rounded-[.375rem]'>
                                <span className='px-[5px] py-[2px] text-[12px] font-bold bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] text-[#ffffff] flex justify-between items-center rounded-t-[.375rem]'>
                                    <span className='text-[.75rem] font-bold text-[#ffffff]'>STRAIGHT FLUSH ( PAKKI ROWN ) 1:40</span>
                                    <span className='hidden lg:block text-[.75rem] font-bold text-[#ffffff]'>Min/Max: 100 - 500000 </span>
                                    <span className='block lg:hidden'>
                                        <i _ngcontent-ng-c4116347132="" data-bs-toggle="dropdown" aria-expanded="false" class="fa fa-info-circle ml-2" data-bs-target="#multiCollapseExample9102906145759" id="#collapse129102906145759"></i>
                                    </span>
                                </span>
                                <div className="flex-[1_0_0%]">
                                    <div className="" id="multiCollapseExample9102906131109">
                                        <div className="relative p-0 m-0 bg-[linear-gradient(180deg,_#ffffff_,_#ffffff_42%)] rounded-bl-[6px]">
                                            <div className="suspended-casino">
                                                <span class="stats-text">Suspended</span>
                                            </div>
                                            <div className="flex odd-row playerABgTie">
                                                <div class="flex-[1_0_0%] flex flex-col items-center justify-center">
                                                    <div className="pb-[.25rem]">
                                                        <span class="flex items-center justify-center text-[.75rem] font-bold text-[#23282c]"> PLAYER A </span>
                                                    </div>
                                                    <div appratehighlight="" className="w-[150px] rounded-[5px] py-[2px] [box-shadow:0_2px_7px_1px_#67828be6] bg-[#72bbef] cursor-pointer">
                                                        <a className="flex  flex-col items-center justify-center no-underline font-semibold text-[#000]"
                                                        >
                                                            1.98
                                                            <span className="text-[11px] font-normal flex justify-center">1353664</span>
                                                        </a>
                                                    </div>
                                                    <div class="d-flex align-items-center p-0">
                                                    </div>
                                                </div>
                                                <div class="flex-[1_0_0%] flex flex-col items-center justify-center">
                                                    <div className="pb-[.25rem]">
                                                        <span class="flex items-center justify-center text-[.75rem] font-bold text-[#23282c]"> PLAYER B </span>
                                                    </div>
                                                    <div appratehighlight="" className="w-[150px] rounded-[5px] py-[2px] [box-shadow:0_2px_7px_1px_#67828be6] bg-[#72bbef] cursor-pointer">
                                                        <a className="flex  flex-col items-center justify-center no-underline font-semibold text-[#000]"
                                                        >
                                                            1.98
                                                            <span className="text-[11px] font-normal flex justify-center">1353664</span>
                                                        </a>
                                                    </div>
                                                    <div class="d-flex align-items-center p-0">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-span-12 relative flex flex-col [word-wrap:break-word] border-[1px] border-[#0000002d] rounded-[.375rem]'>
                                <span className='px-[5px] py-[2px] text-[12px] font-bold bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] text-[#ffffff] flex justify-between items-center rounded-t-[.375rem]'>
                                    <span className='text-[.75rem] font-bold text-[#ffffff]'>TRIO ( TEEN ) 1:75</span>
                                    <span className='hidden lg:block text-[.75rem] font-bold text-[#ffffff]'>Min/Max: 100 - 500000 </span>
                                    <span className='block lg:hidden'>
                                        <i _ngcontent-ng-c4116347132="" data-bs-toggle="dropdown" aria-expanded="false" class="fa fa-info-circle ml-2" data-bs-target="#multiCollapseExample9102906145759" id="#collapse129102906145759"></i>
                                    </span>
                                </span>
                                <div className="flex-[1_0_0%]">
                                    <div className="" id="multiCollapseExample9102906131109">
                                        <div className="relative p-0 m-0 bg-[linear-gradient(180deg,_#ffffff_,_#ffffff_42%)] rounded-bl-[6px]">
                                            <div className="suspended-casino">
                                                <span class="stats-text">Suspended</span>
                                            </div>
                                            <div className="flex odd-row playerABgTie">
                                                <div class="flex-[1_0_0%] flex flex-col items-center justify-center">
                                                    <div className="pb-[.25rem]">
                                                        <span class="flex items-center justify-center text-[.75rem] font-bold text-[#23282c]"> PLAYER A </span>
                                                    </div>
                                                    <div appratehighlight="" className="w-[150px] rounded-[5px] py-[2px] [box-shadow:0_2px_7px_1px_#67828be6] bg-[#72bbef] cursor-pointer">
                                                        <a className="flex  flex-col items-center justify-center no-underline font-semibold text-[#000]"
                                                        >
                                                            1.98
                                                            <span className="text-[11px] font-normal flex justify-center">1353664</span>
                                                        </a>
                                                    </div>
                                                    <div class="d-flex align-items-center p-0">
                                                    </div>
                                                </div>
                                                <div class="flex-[1_0_0%] flex flex-col items-center justify-center">
                                                    <div className="pb-[.25rem]">
                                                        <span class="flex items-center justify-center text-[.75rem] font-bold text-[#23282c]"> PLAYER B </span>
                                                    </div>
                                                    <div appratehighlight="" className="w-[150px] rounded-[5px] py-[2px] [box-shadow:0_2px_7px_1px_#67828be6] bg-[#72bbef] cursor-pointer">
                                                        <a className="flex  flex-col items-center justify-center no-underline font-semibold text-[#000]"
                                                        >
                                                            1.98
                                                            <span className="text-[11px] font-normal flex justify-center">1353664</span>
                                                        </a>
                                                    </div>
                                                    <div class="d-flex align-items-center p-0">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-span-12 relative flex flex-col [word-wrap:break-word] border-[1px] border-[#0000002d] rounded-[.375rem]'>
                                <span className='px-[5px] py-[2px] text-[12px] font-bold bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] text-[#ffffff] flex justify-between items-center rounded-t-[.375rem]'>
                                    <span className='text-[.75rem] font-bold text-[#ffffff]'>PUTLA ( 1 PICTURE IN GAME ) 1:0.70</span>
                                    <span className='hidden lg:block text-[.75rem] font-bold text-[#ffffff]'>Min/Max: 100 - 500000 </span>
                                    <span className='block lg:hidden'>
                                        <i _ngcontent-ng-c4116347132="" data-bs-toggle="dropdown" aria-expanded="false" class="fa fa-info-circle ml-2" data-bs-target="#multiCollapseExample9102906145759" id="#collapse129102906145759"></i>
                                    </span>
                                </span>
                                <div className="flex-[1_0_0%]">
                                    <div className="" id="multiCollapseExample9102906131109">
                                        <div className="relative p-0 m-0 bg-[linear-gradient(180deg,_#ffffff_,_#ffffff_42%)] rounded-bl-[6px]">
                                            <div className="suspended-casino">
                                                <span class="stats-text">Suspended</span>
                                            </div>
                                            <div className="flex odd-row playerABgTie">
                                                <div class="flex-[1_0_0%] flex flex-col items-center justify-center">
                                                    <div className="pb-[.25rem]">
                                                        <span class="flex items-center justify-center text-[.75rem] font-bold text-[#23282c]"> PLAYER A </span>
                                                    </div>
                                                    <div appratehighlight="" className="w-[150px] rounded-[5px] py-[2px] [box-shadow:0_2px_7px_1px_#67828be6] bg-[#72bbef] cursor-pointer">
                                                        <a className="flex  flex-col items-center justify-center no-underline font-semibold text-[#000]"
                                                        >
                                                            1.98
                                                            <span className="text-[11px] font-normal flex justify-center">1353664</span>
                                                        </a>
                                                    </div>
                                                    <div class="d-flex align-items-center p-0">
                                                    </div>
                                                </div>
                                                <div class="flex-[1_0_0%] flex flex-col items-center justify-center">
                                                    <div className="pb-[.25rem]">
                                                        <span class="flex items-center justify-center text-[.75rem] font-bold text-[#23282c]"> PLAYER B </span>
                                                    </div>
                                                    <div appratehighlight="" className="w-[150px] rounded-[5px] py-[2px] [box-shadow:0_2px_7px_1px_#67828be6] bg-[#72bbef] cursor-pointer">
                                                        <a className="flex  flex-col items-center justify-center no-underline font-semibold text-[#000]"
                                                        >
                                                            1.98
                                                            <span className="text-[11px] font-normal flex justify-center">1353664</span>
                                                        </a>
                                                    </div>
                                                    <div class="d-flex align-items-center p-0">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-span-12 relative flex flex-col [word-wrap:break-word] border-[1px] border-[#0000002d] rounded-[.375rem]'>
                                <span className='px-[5px] py-[2px] text-[12px] font-bold bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] text-[#ffffff] flex justify-between items-center rounded-t-[.375rem]'>
                                    <span className='text-[.75rem] font-bold text-[#ffffff]'>PUTLA ( 2 PICTURE IN GAME ) 1:4</span>
                                    <span className='hidden lg:block text-[.75rem] font-bold text-[#ffffff]'>Min/Max: 100 - 500000 </span>
                                    <span className='block lg:hidden'>
                                        <i _ngcontent-ng-c4116347132="" data-bs-toggle="dropdown" aria-expanded="false" class="fa fa-info-circle ml-2" data-bs-target="#multiCollapseExample9102906145759" id="#collapse129102906145759"></i>
                                    </span>
                                </span>
                                <div className="flex-[1_0_0%]">
                                    <div className="" id="multiCollapseExample9102906131109">
                                        <div className="relative p-0 m-0 bg-[linear-gradient(180deg,_#ffffff_,_#ffffff_42%)] rounded-bl-[6px]">
                                            <div className="suspended-casino">
                                                <span class="stats-text">Suspended</span>
                                            </div>
                                            <div className="flex odd-row playerABgTie">
                                                <div class="flex-[1_0_0%] flex flex-col items-center justify-center">
                                                    <div className="pb-[.25rem]">
                                                        <span class="flex items-center justify-center text-[.75rem] font-bold text-[#23282c]"> PLAYER A </span>
                                                    </div>
                                                    <div appratehighlight="" className="w-[150px] rounded-[5px] py-[2px] [box-shadow:0_2px_7px_1px_#67828be6] bg-[#72bbef] cursor-pointer">
                                                        <a className="flex  flex-col items-center justify-center no-underline font-semibold text-[#000]"
                                                        >
                                                            1.98
                                                            <span className="text-[11px] font-normal flex justify-center">1353664</span>
                                                        </a>
                                                    </div>
                                                    <div class="d-flex align-items-center p-0">
                                                    </div>
                                                </div>
                                                <div class="flex-[1_0_0%] flex flex-col items-center justify-center">
                                                    <div className="pb-[.25rem]">
                                                        <span class="flex items-center justify-center text-[.75rem] font-bold text-[#23282c]"> PLAYER B </span>
                                                    </div>
                                                    <div appratehighlight="" className="w-[150px] rounded-[5px] py-[2px] [box-shadow:0_2px_7px_1px_#67828be6] bg-[#72bbef] cursor-pointer">
                                                        <a className="flex  flex-col items-center justify-center no-underline font-semibold text-[#000]"
                                                        >
                                                            1.98
                                                            <span className="text-[11px] font-normal flex justify-center">1353664</span>
                                                        </a>
                                                    </div>
                                                    <div class="d-flex align-items-center p-0">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-span-12 relative flex flex-col [word-wrap:break-word] border-[1px] border-[#0000002d] rounded-[.375rem]'>
                                <span className='px-[5px] py-[2px] text-[12px] font-bold bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] text-[#ffffff] flex justify-between items-center rounded-t-[.375rem]'>
                                    <span className='text-[.75rem] font-bold text-[#ffffff]'>LOVE MARRIAGE ( Q & K WITH SAME SUIT ) 1:25</span>
                                    <span className='hidden lg:block text-[.75rem] font-bold text-[#ffffff]'>Min/Max: 100 - 500000 </span>
                                    <span className='block lg:hidden'>
                                        <i _ngcontent-ng-c4116347132="" data-bs-toggle="dropdown" aria-expanded="false" class="fa fa-info-circle ml-2" data-bs-target="#multiCollapseExample9102906145759" id="#collapse129102906145759"></i>
                                    </span>
                                </span>
                                <div className="flex-[1_0_0%]">
                                    <div className="" id="multiCollapseExample9102906131109">
                                        <div className="relative p-0 m-0 bg-[linear-gradient(180deg,_#ffffff_,_#ffffff_42%)] rounded-bl-[6px]">
                                            <div className="suspended-casino">
                                                <span class="stats-text">Suspended</span>
                                            </div>
                                            <div className="flex odd-row playerABgTie">
                                                <div class="flex-[1_0_0%] flex flex-col items-center justify-center">
                                                    <div className="pb-[.25rem]">
                                                        <span class="flex items-center justify-center text-[.75rem] font-bold text-[#23282c]"> PLAYER A </span>
                                                    </div>
                                                    <div appratehighlight="" className="w-[150px] rounded-[5px] py-[2px] [box-shadow:0_2px_7px_1px_#67828be6] bg-[#72bbef] cursor-pointer">
                                                        <a className="flex  flex-col items-center justify-center no-underline font-semibold text-[#000]"
                                                        >
                                                            1.98
                                                            <span className="text-[11px] font-normal flex justify-center">1353664</span>
                                                        </a>
                                                    </div>
                                                    <div class="d-flex align-items-center p-0">
                                                    </div>
                                                </div>
                                                <div class="flex-[1_0_0%] flex flex-col items-center justify-center">
                                                    <div className="pb-[.25rem]">
                                                        <span class="flex items-center justify-center text-[.75rem] font-bold text-[#23282c]"> PLAYER B </span>
                                                    </div>
                                                    <div appratehighlight="" className="w-[150px] rounded-[5px] py-[2px] [box-shadow:0_2px_7px_1px_#67828be6] bg-[#72bbef] cursor-pointer">
                                                        <a className="flex  flex-col items-center justify-center no-underline font-semibold text-[#000]"
                                                        >
                                                            1.98
                                                            <span className="text-[11px] font-normal flex justify-center">1353664</span>
                                                        </a>
                                                    </div>
                                                    <div class="d-flex align-items-center p-0">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-span-12 relative flex flex-col [word-wrap:break-word] border-[1px] border-[#0000002d] rounded-[.375rem]'>
                                <span className='px-[5px] py-[2px] text-[12px] font-bold bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] text-[#ffffff] flex justify-between items-center rounded-t-[.375rem]'>
                                    <span className='text-[.75rem] font-bold text-[#ffffff]'>BF LOVE GF ( J & Q WITH SAME SUIT ) 1:25</span>
                                    <span className='hidden lg:block text-[.75rem] font-bold text-[#ffffff]'>Min/Max: 100 - 500000 </span>
                                    <span className='block lg:hidden'>
                                        <i _ngcontent-ng-c4116347132="" data-bs-toggle="dropdown" aria-expanded="false" class="fa fa-info-circle ml-2" data-bs-target="#multiCollapseExample9102906145759" id="#collapse129102906145759"></i>
                                    </span>
                                </span>
                                <div className="flex-[1_0_0%]">
                                    <div className="" id="multiCollapseExample9102906131109">
                                        <div className="relative p-0 m-0 bg-[linear-gradient(180deg,_#ffffff_,_#ffffff_42%)] rounded-bl-[6px]">
                                            <div className="suspended-casino">
                                                <span class="stats-text">Suspended</span>
                                            </div>
                                            <div className="flex odd-row playerABgTie">
                                                <div class="flex-[1_0_0%] flex flex-col items-center justify-center">
                                                    <div className="pb-[.25rem]">
                                                        <span class="flex items-center justify-center text-[.75rem] font-bold text-[#23282c]"> PLAYER A </span>
                                                    </div>
                                                    <div appratehighlight="" className="w-[150px] rounded-[5px] py-[2px] [box-shadow:0_2px_7px_1px_#67828be6] bg-[#72bbef] cursor-pointer">
                                                        <a className="flex  flex-col items-center justify-center no-underline font-semibold text-[#000]"
                                                        >
                                                            1.98
                                                            <span className="text-[11px] font-normal flex justify-center">1353664</span>
                                                        </a>
                                                    </div>
                                                    <div class="d-flex align-items-center p-0">
                                                    </div>
                                                </div>
                                                <div class="flex-[1_0_0%] flex flex-col items-center justify-center">
                                                    <div className="pb-[.25rem]">
                                                        <span class="flex items-center justify-center text-[.75rem] font-bold text-[#23282c]"> PLAYER B </span>
                                                    </div>
                                                    <div appratehighlight="" className="w-[150px] rounded-[5px] py-[2px] [box-shadow:0_2px_7px_1px_#67828be6] bg-[#72bbef] cursor-pointer">
                                                        <a className="flex  flex-col items-center justify-center no-underline font-semibold text-[#000]"
                                                        >
                                                            1.98
                                                            <span className="text-[11px] font-normal flex justify-center">1353664</span>
                                                        </a>
                                                    </div>
                                                    <div class="d-flex align-items-center p-0">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-span-12 mt-[5px] bg-[#000] p-[5px] text-[#fff] inline-block overflow-x-auto mb-[100px] whitespace-nowrap'>
                                <h5 className='inline-block font-bold text-[1.07375rem] m-0'>Recent Result</h5>
                                <ul className='inline-block whitespace-nowrap'>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#72bbef] '>
                                        <button type='button' className='text-[#333]' onClick={showModal}>A</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]' >B</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]'>B</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]'>B</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]'>B</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#72bbef] '>
                                        <button type='button' className='text-[#333]'>A</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]'>B</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]'>B</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]'>B</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]'>B</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]'>B</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]'>B</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]'>B</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]'>B</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]'>B</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]'>B</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]'>B</button>
                                    </li>
                                    <li className='w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-bold inline-block rounded-[15px] bg-[#f9a9ba] '>
                                        <button type='button' className='text-[#333]'>B</button>
                                    </li>
                                </ul>
                                <Modal className='recent-result-modal relative top-1 lg:top-7 w-[500px]' open={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel}>
                                    <div className='grid grid-cols-12'>
                                        <div className='col-span-12 flex rounded-t-[8px] items-center h-[45px] justify-between p-[10px] bg-[linear-gradient(-180deg,_#315195_0%,_#14213D_100%)] border-[1px] border-[solid] border-[#ffffff] text-[#ffffff]'>
                                            <span className='leading-normal text-[.938rem] font-bold'> 20-20 TEEN PATTI </span>
                                            <button type="button" className='text-[1.3rem] font-bold'>×</button>
                                        </div>
                                        <div className='col-span-12 max-h-[80vh] overflow-y-auto '>
                                            <div className='grid grid-cols-12 mb-[80px]'>
                                                <div className='col-span-12 p-[.5rem]'>
                                                    <h6 className='font-bold text-[1rem] text-end'>Round Id: 9102906141719 </h6>
                                                </div>
                                                <div className='col-span-6 text-center border-r-[1px] border-r-[#ddd] py-[5px] my-[10px] float-left'>
                                                    <h6 className='text-[1rem] font-bold' >Player A</h6>
                                                    <div className=''>
                                                        <div className='flex justify-center'>
                                                            <img className='w-[62px] border-[1px] border-black rounded-[17px] m-[6px]' src="/cards/0.png"></img>
                                                            <img className='w-[62px] border-[1px] border-black rounded-[17px] m-[6px]' src="/cards/C9_.png"></img>
                                                            <img className='w-[62px] border-[1px] border-black rounded-[17px] m-[6px]' src="/cards/D10_.png"></img>
                                                        </div>
                                                        <button className='bg-[#28a745] py-[5px] px-[10px] text-[#fff] text-[20px] font-bold block my-[10px] mx-auto rounded-[.375rem]'>Winner</button>
                                                    </div>
                                                </div>
                                                <div className='col-span-6 text-center border-r-[1px] border-r-[#ddd] py-[5px] my-[10px] float-left'>
                                                    <h6 className='text-[1rem] font-bold' >Player B</h6>
                                                    <div className=''>
                                                        <div className='flex justify-center'>
                                                            <img className='w-[62px] border-[1px] border-black rounded-[17px] m-[6px]' src="/cards/0.png"></img>
                                                            <img className='w-[62px] border-[1px] border-black rounded-[17px] m-[6px]' src="/cards/C9_.png"></img>
                                                            <img className='w-[62px] border-[1px] border-black rounded-[17px] m-[6px]' src="/cards/D10_.png"></img>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-span-12 border-y-[1px] border-[#ccc] py-[10px]'>
                                                    <h6 className='text-[.8rem] font-bold text-center'>WINNER</h6>
                                                    <div className="w-[50%] inline-block text-center text-[.75rem] p-[.25rem]">
                                                        PLAYER A -
                                                        <i className="fa fa-trophy winner-icon"></i>
                                                    </div>
                                                    <div className="w-[50%] inline-block text-center text-[.75rem] p-[.25rem]">
                                                        PLAYER B -
                                                        <i className="fa fa-trophy winner-icon loser-icon"></i>
                                                    </div>
                                                </div>
                                                <div className='col-span-12 border-y-[1px] border-[#ccc] py-[10px]'>
                                                    <h6 className='text-[.8rem] font-bold text-center'>PAIR ( DUBBLE ) 1:4</h6>
                                                    <div className="w-[50%] inline-block text-center text-[.75rem] p-[.25rem]">
                                                        PLAYER A -
                                                        <i className="fa fa-trophy winner-icon"></i>
                                                    </div>
                                                    <div className="w-[50%] inline-block text-center text-[.75rem] p-[.25rem]">
                                                        PLAYER B -
                                                        <i className="fa fa-trophy winner-icon loser-icon"></i>
                                                    </div>
                                                </div>
                                                <div className='col-span-12 border-y-[1px] border-[#ccc] py-[10px]'>
                                                    <h6 className='text-[.8rem] font-bold text-center'>FLUSH ( COLOR ) 1:8</h6>
                                                    <div className="w-[50%] inline-block text-center text-[.75rem] p-[.25rem]">
                                                        PLAYER A -
                                                        <i className="fa fa-trophy winner-icon"></i>
                                                    </div>
                                                    <div className="w-[50%] inline-block text-center text-[.75rem] p-[.25rem]">
                                                        PLAYER B -
                                                        <i className="fa fa-trophy winner-icon loser-icon"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </div>

                </div >
            }
        </>
    )
}
export default Casino;
